import { pickImage, pickUrl } from 'editor/helpers/images/image-size-helper'
import GlobalProperties from 'core/helpers/global/global-properties'

export const anchorStyle = 'width: 100%; height: 100%; display: block; position: absolute; top: 0; left: 0;z-index: 1'
export const lowResScale = 0.1

export const buildOuterAttributes = (dataType, property) => `data-type="${dataType}" data-property="${property}"`

export const initStyles = (width, height) => {
  if (height && width) {
    const ratio = (height / width) * 100
    return `background-size:cover;padding-top:${ratio}%;`
  }
  return ''
}

export const getImageObject = (dataType, prop, controlType) => {
  const imgValue = pickUrl(prop, controlType)
  const imgValueRetina = pickUrl(prop, controlType, true)
  const imgValueLowRes = dataType !== 'logo' ? pickUrl(prop, controlType, null, lowResScale) : imgValue

  return {
    imgValue,
    imgValueRetina,
    imgValueLowRes
  }
}

export const buildCropStyle = (crop, styles) => {
  if (crop) {
    if (crop.offsetX !== undefined) {
      styles += `background-position-x:${crop.offsetX * 100}%;`
    }
    if (crop.offsetY !== undefined) {
      styles += `background-position-y:${crop.offsetY * 100}%;`
    }
  } else {
    styles += 'background-position: center center;'
  }
  return styles
}

export const handleRenderWithCallback = (binding, prop, classList, nodeName, outerAttributes, anchorTag, styles, controlType, img, callback) => {
  const { altText, parallax } = prop
  const { imgValue, imgValueLowRes, imgValueRetina } = img

  if (!callback || typeof callback !== 'function') {
    throw new Error('Callback needs to be defined.')
  }

  // Dont lazy load first 2 sections for Google Pagespeed score.
  const useLazyLoad = binding._index >= 2 && !GlobalProperties._loadedImages[imgValue]
  let innerAttributes = parallax ? ` data-parallax="${parallax}" class="${classList}"` : ` class="${classList} ${useLazyLoad ? 'loader' : ''}"`

  if (nodeName.match(/img/i)) {
    if (altText) {
      innerAttributes += `alt="${altText}"`
    }
    return `<div ${outerAttributes}>
      <img      
      ${innerAttributes}
      src="${imgValue}" /> ${anchorTag} </div>`
  } else {
    if (!useLazyLoad) {
      styles += pickImage(prop, controlType, null)
    } else {
      // set data attributes for blur-in-images-mixin (lazy loading images)
      innerAttributes += ` role="img" data-src="${imgValueLowRes}" data-src-normal="${imgValue}" data-src-retina="${imgValueRetina}"`
    }
    if (altText) {
      innerAttributes += ` aria-label="${altText}"`
    }
    return callback(innerAttributes, styles)
  }
}
