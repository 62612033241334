import { getHref } from '../utils'
import { initStyles, anchorStyle, getImageObject, buildOuterAttributes, buildCropStyle, handleRenderWithCallback } from './build-image-attributes'

const imageHelper = (prop, property, nodeName, classList, data, controlType, dataType = 'image') => {
  const binding = data.data.root
  const { link, width, height, logoSizeClass, crop } = prop
  const linkHref = getHref(link)

  let outerAttributes = buildOuterAttributes(dataType, property)

  let anchorTag = ''
  if (dataType === 'logo') {
    outerAttributes += ` class="${logoSizeClass || 'logo-medium'}"`
    if (IS_RUNTIME || IS_PUBLISHER) {
      anchorTag = `<a href="/" data-href="/" style="${anchorStyle}"></a>`
    }
  } else if (linkHref) {
    anchorTag = `<a href="${linkHref}" data-href="${linkHref}" style="${anchorStyle}"
      ${link.shouldOpenInTab ? 'target="_blank"' : ''}
      ></a>`
  }

  const { imgValue, imgValueRetina, imgValueLowRes } = getImageObject(dataType, prop, controlType)
  if (!imgValue) {
    return ''
  }
  let styles = initStyles(width, height)

  if (!!binding.roundedCorners) { // eslint-disable-line
    styles += `border-radius: ${binding.roundedCorners}%;`
  }

  if (binding.borderWidth) {
    styles += `
      border-style: solid;
      border-width: ${binding.borderWidth}px;
    `
    classList += ` ${binding.borderColor ? binding.borderColor.class : 'custom-border-cl3'}`
  }

  if (binding.dropShadow) {
    styles += 'box-shadow: 0 8px 20px rgba(0, 0, 0, 0.6);'
  }
  styles = buildCropStyle(crop, styles)

  return handleRenderWithCallback(binding, prop, classList, nodeName, outerAttributes, anchorTag, styles, controlType, {
    imgValue,
    imgValueLowRes,
    imgValueRetina
  }, (innerAttributes, styles) => {
    if (binding.hover) {
      return `<div class="${binding.hover}" style="overflow: hidden; height: 100%; border-radius: ${binding.roundedCorners}%; ${binding.dropShadow ? 'box-shadow: 0 8px 20px rgba(0, 0, 0, 0.6);' : null}">
        <${nodeName}
          ${outerAttributes}
          ${innerAttributes}
          style="${styles}"
          data-setting="border"
        >${anchorTag}</${nodeName}>
      </div>`
    }

    return `<${nodeName}
      ${outerAttributes}
      ${innerAttributes}
      style="${styles}"
      data-setting="border"
    >${anchorTag}</${nodeName}>`
  })
}
export default imageHelper
