import BaseControl from 'editor/controls/base-control'

// Core
import { SECTION_STATUS as STATUS } from 'core/helpers/global/enums'
import GlobalProperties from 'core/helpers/global/global-properties'

///////////////////////////////////
/////////////////////////////////////////////////////////////
////////////////////////////////////////////////////

///////////////////////////////////////
/////////

////////////////
/////////////////////////////////////////////////////////////////////

//////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////

///////////////////////////////////////////////////
/////////

import defer from 'lodash/defer'
import $ from 'jquery'

const movementState = {
  startLocation: 0,
  value: 0,
  direction: ''
}

let balloonConfig

class SectionControl extends BaseControl {
  constructor (controller, model, Layout) {
    super(controller, () => Layout, model, 'section')

    this.widgetId = ''
    this.element = ''
  }

  renderHtml (parentBinding, index) {
    if (this.controller.colorManager) {
      this.controller.colorManager.render()
    }
    return super.renderHtml(parentBinding, index)
  }

  bindLayout (element, data) {
    super.bindLayout(element, data)
    this.element = element
    this.data = data

    if (this.controller.editable()) {
      if (this.controller.status !== STATUS.READONLY) {
        // if we're edditing we insert the background control so we don't have to re-render the background image everytime we change opacity
        // This used to be in mail, but due to changes in the system this has been marked obsolete.
        // const backgroundControl = this.controller.getControlOfType('background')
        // const backgroundElement = makeDomElement(backgroundControl.renderHtml())
        // backgroundControl.setElement(backgroundElement, true)
        // element.insertBefore(backgroundElement, element.childNodes[0])

        // add the sectionId to the <section /> element
        const sectionId = this.model.layout.section.id
        element.dataset.sectionKey = sectionId
      }

      this.addButton = element.querySelector('.kv-section-controls-add-section')
      this.removeButton = element.querySelector('.kv-section-remove')
      this.upButton = element.querySelector('.kv-section-up')
      this.downButton = element.querySelector('.kv-section-down')
      this.settingsButton = element.querySelector('.kv-section-settings')
      this.dragHandle = element.querySelector('.kv-section-drag')
      this.backgroundButton = element.querySelector('.kv-section-background')
      this.preventEditOverlay = element.querySelector('.kv-prevent-edit-overlay')
      this.layoutButton = element.querySelector('.kv-section-carousel')
      this.canOpen = true
      element.addEventListener('click', this.handleClick)

      // Swipeable containers
      element.addEventListener('touchstart', event => {
        const touchEvent = event.changedTouches[0]
        movementState.startLocation = parseInt(touchEvent.clientX)
        movementState.startLocationY = parseInt(touchEvent.clientY)
      })

      element.addEventListener('touchmove', event => {
        let section
        const touchEvent = event.changedTouches[0]
        const distance = (parseInt(touchEvent.clientX) - movementState.startLocation)
        const ydistance = Math.abs((parseInt(touchEvent.clientY) - movementState.startLocationY))
        const distanceValue = Math.abs(distance)

        movementState.direction = distance
        window.clearTimeout(EditorManager.current().closeTimer)

        if (distanceValue > 80 && this.canOpen && !EditorManager.current().open && ydistance < 60) {
          this.canOpen = false
          defer(() => EditorManager.current().switchSectionLayout(this, null, null, (startElementIx) => {
            if (distance > 80) {
              section = startElementIx - 1
            } else if (distance < -80) {
              section = startElementIx + 1
            }

            EditorManager.current().carousel.gotoItem(section, true)
            EditorManager.current().startMobileCloseTimer()
          }))
        }
      })

      element.addEventListener('touchend', event => {
        this.canOpen = true
        if (EditorManager.current().open) {
          EditorManager.current().startMobileCloseTimer()
        }
      })

      if (this.duplicateButton) {
        this.duplicateButton.addEventListener('click', e => this.controller.duplicateSection(e))
      }

      if (this.dragHandle) {
        this.dragHandle.addEventListener('click', e => defer(() => this.openReorderMode(e)))
        this.dragHandle.addEventListener('mousedown', e => defer(() => this.openReorderModeWhenPressed(e, true)))
        this.dragHandle.addEventListener('mouseup', e => defer(() => this.openReorderModeWhenPressed(e, false)))
      }

      if (this.backgroundButton) {
        this.backgroundButton.addEventListener('click', e => this.controller.selectBackground(e))
      }

      if (this.addButton) {
        this.addButton.addEventListener('click', e => this.onAddSection(e))
      }

      if (this.preventEditOverlay) {
        this.preventEditOverlay.addEventListener('click', this.preventOverlayClick)
      }

      if (this.removeButton) {
        if (!FeatureDependanciesLogic.canDeleteSection(this.model, this.controller.getPageController().model)) {
          this.removeButton.remove()
        } else {
          this.removeButton.addEventListener('click', e => this.onRemoveSection(e))
        }
      }

      if (this.upButton) {
        this.upButton.addEventListener('click', () => this.onMoveSectionUp())
      }

      if (this.downButton) {
        this.downButton.addEventListener('click', () => this.onMoveSectionDown())
      }

      if (this.layoutButton) {
        this.layoutButton.addEventListener('click', e => {
          // defer cause we want to select the section fitst
          defer(() => EditorManager.current().switchSectionLayout(this, e, element))
        })
      }

      if (this.settingsButton) {
        this.settingsButton.addEventListener('click', () => this.onClickSettings())
      }

      this.setTooltips(element)
      this.addSettingsButton(element)
      this.cleanExcludedElementToolbars()
      this.updateKVControls()

      this.site = element.closest('.kv-site')
      if (!this.site) {
        this.site = $('.kv-site')[0]
      }
    }
  }

  updateKVControls () {
    function setVisible (el, visible) {
      if (el) {
        el.style.display = visible ? '' : 'none'
      }
    }
    const allSectionModels = this.controller.getPageController().getAllSections()

    const isHeader = allSectionModels[0] === this.model
    const isHeaderSibling = allSectionModels[1] === this.model
    const isFooterSibling = allSectionModels[allSectionModels.length - 1] === this.model
    const isFooter = allSectionModels[allSectionModels.length - 1] === this.model

    setVisible(this.upButton, !isHeader && !isFooter && !isHeaderSibling)
    setVisible(this.downButton, !isHeader && !isFooter && !isFooterSibling)
  }

//////////////////
///////////////////////////////////////////////////////////
///////////////////////////
//////////////////////////////////////////////////////////////////
////////////
/////
///////////////////////////
/////////////////////////////////////////////////
////////////////////////////////////////////////////////////////
//////////////////////////////////////
///

///////////////////////
/////////////////////////////////////////////////////////////////
///

///////////////////////
/////////////////////////////////////////////////////////////////
///

//////////////////////////
/////////////////////////////////////////////////////////////////////////
///

///////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////
///

/////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////
///

//////////////////////////
///////////////////////////////////////////////////////
///

//////////////////////////////////////////////////
///////////////////////////////////
/////////////////////////////////////////
/////////////////////
//////////////////////
////////////
/////
////////////////////////////////////////////////
/////////////////////////////
///////////////////////////
///

//////////////////////
////////////////////////////////////////
/////////////////////////////////////////////////////////////
/////
///

/////////////
///////////////////////////////////////////////////////
///

///////////////////////////
//////////////////////
///

//////////////////////////////
//////////////////////////////////////////////
/////////////
//////////////////////
////////////////////////////////
///////
//////////////////////
/////////////////////////
////////////////////
/////////
///////
/////
///

////////////////////////////////////////
///////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////

///////////////////////////////////////////////
//////////////////////////////
/////

/////////////////////////////////////////////////
////////////////////////////////
/////
///

/////////////////////////
//////////////////////////////////////////
////////////////////////////////////////////////////////
///////////////////////////
///////////////////////////////////////////
///////////
/////////////////////////////////////////
/////////////////////
//////////////////////////////////////////////////////////////////////////////////
////////////
///////////
//////////////////////////////////////////////
/////////////////////
///////////////////////////////////////////////////////////////////////////////////////
////////////
///////////
/////////////////////////////////////////////
/////////////////////
////////////////////////////////////////////////////////////////////////////////////
////////////
///////////
/////////////////////////////////////////////////////////
/////////////////////
////////////////////////////////////////////////////////////////////////////////////////
////////////
///////////
///////////////////////////////////////////////
/////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////
////////////
///////////
/////////////////////////////////////////////
/////////////////////
//////////////////////////////////////////////////////////////////////////////////////
////////////
///////////
///////////////////////////////////////////
/////////////////////
///////////////////////////////////////////////////////////////////////////////////////////
///////////
///////////
/////////////////////////////////////////////////////
///////
/////
///

///////////////////////////////
/////////////////////////////////////////////////
/////////////////////////////////////////////////////
////////////////////////////////////////////////////////

//////////////////////
///////////////////////////
///////////////////////////////
///////
/////

/////////////////////////////////////
//////////////////////////
//////////////////////////////
///////
////////////////////////////
////////////////////////////////
///////
//////////////////////////////
//////////////////////////////////
///////
/////
///

///////////////////////
/////////////////////////
/////////////////////////////////////////////////
/////////////////////////////////////
///////////////////////////////////////////////////

///////////////////////
///
///////////
}
export default SectionControl
