import each from 'lodash/each'
import last from 'lodash/last'

import $ from 'jquery'

// Import from editor instead of core to use product-specific settings like mixins and render differences.
import BaseControl from 'editor/controls/base-control'
import Features from 'editor/managers/features'

class ContentControl extends BaseControl {
  constructor (controller, model) {
    super(controller, controller.getSectionController().getSectionLayout, model, 'content')
  }

  mount () {
    Features.mount(this)
  }

  registerView (addOrDeleteBool) {
    // don't re-render when page model in self changes
    super.registerView(addOrDeleteBool)

    // also render the background from here
    this.controller.registerView(this, this.model.background, addOrDeleteBool)
  }

  bindLayout (element, data) {
    super.bindLayout(element, data)

    // needed in editor to handle certain parts of the code
    if (!element.classList.contains('kv-content')) {
      element.classList.add('kv-content')
    }

    const tabs = Array.from(element.querySelectorAll('.tab'))
    if (tabs.length > 0) {
      const viewContainer = element.querySelector('.view-container')
      viewContainer.style.width = `${tabs.length * 100}%`

      const views = Array.from(element.querySelectorAll('.view'))
      const widthPercentage = 100 / tabs.length
      views.forEach(v => {
        v.style.width = `${widthPercentage}%`
      })

      tabs[0].classList.add('active')
      tabs.forEach(x => {
        x.addEventListener('click', event => {
          tabs.forEach(t => t.classList.remove('active'))
          x.classList.add('active')
          const index = x.dataset.index
          viewContainer.style.transform = 'translateX(-' + index * widthPercentage + '%)'
        })
      })
    }

    const nextSectionButton = element.querySelector('.kv-next-section')
    if (nextSectionButton && !this.controller.editable()) {
      const sectionControllers = this.controller.getPageController().getSectionControllers()
      const currentSectionController = this.controller.getSectionController()
      // Remove element when there is no next section
      if (last(sectionControllers) === currentSectionController) {
        nextSectionButton.remove()
      } else {
        nextSectionButton.addEventListener('click', () => {
          const currentIndex = sectionControllers.indexOf(currentSectionController)
          const nextSection = sectionControllers[currentIndex + 1]
          if (nextSection) {
            this.scrollToSection(nextSection.control.element, data)
          }
        })
      }
    }

    const menuButton = element.querySelector('.kv-menu')
    if (menuButton) {
      const contentElement = element
      if (!this.controller.editable()) {
        menuButton.addEventListener('click', () => {
          if (contentElement.classList.contains('menu-open')) {
            contentElement.classList.remove('menu-open')
            contentElement.classList.add('menu-close')
          } else {
            contentElement.classList.add('menu-open')
            contentElement.classList.remove('menu-close')
          }
        })
      }
    }

    // Fix for old links
    each(element.querySelectorAll('a'), e => {
      if (e.closest('[data-type="text"]')) {
        if (e.dataset.href && !e.href) {
          e.href = e.dataset.href
        }

        if (e.dataset.target && !e.target) {
          e.target = e.dataset.target
        }

        if (typeof window !== 'undefined' && window.top !== window.self) {
          if (!e.href.startsWith(window.location.origin)) {
            e.target = '_blank'
          }
        }

        // Fix for preview links
        $(e).click(event => {
          const href = e.href.replace(window.location.origin, '')
          if (!this.tryNavigateToPage(href)) {
            event.stopPropagation()
            return true
          }
          return false
        })
      }
    })

    const navigationElement = $('navigation, nav', element)
    $('*[data-href]', element).each((index, item) => {
      $(item).click(e => {
        const href = item.dataset.href
        if (!this.tryNavigateToPage(href)) {
          e.stopPropagation()
          return true
        }
        return false
      })
    })

    $('*[data-uri-path]', navigationElement).each((index, link) => {
      $(link).click(e => {
        if (menuButton) {
          const contentElement = element
          contentElement.classList.remove('menu-open')
          contentElement.classList.add('menu-close')
        }
        const editorContext = this.controller.editorContext
        const path = link.dataset.uriPath
        if (path.indexOf('section-') === 0) {
          const sectionId = ~~path.split('-')[1]
          const navLink = document.querySelector(`a[name="section${sectionId}"]`)
          const section = navLink ? navLink.parentElement : undefined
          if (editorContext) {
            const sectionControl = this.controller
              .getPageController()
              .getChildControls()
              .find(i => i.model.id === sectionId)
            if (sectionControl) {
              editorContext.selectSection(sectionControl)
            }
          } else {
            this.scrollToSection(section, data)
          }
        } else {
          if (!this.tryNavigateToPage(path)) {
            e.stopPropagation()
            return true // stil handle the default action
          }
        }
        return false
      })
    })

    if (!this.controller.editable()) {
      each(element.querySelectorAll('*[data-type="datepicker"]'), e => {
        const $e = $(e)
        if ($e.datepicker) {
          $e.datepicker().datepicker('setDate', new Date())
        }
      })
    }

    if (data.fullPage && data._isMainPage) {
      element.classList.add('kv-full-page')
    }

    if (element.querySelector('*[data-type="swiper"]')) {
      this.swiperControl = new this.controller.imports.SwiperControl(element, this.controller)
      this.swiperControl.initialize()
    }
    Features.afterRender(this, data, element)
  }

  scrollToSection (section, data) {
    if (section) {
      const rect = section.getBoundingClientRect()
      const fixedHeader = document.querySelector('.kv-fixed-header')
      const scrollTop = rect.top + window.pageYOffset - (fixedHeader ? fixedHeader.offsetHeight : 0)
      if (this.controller.imports.Velocity) {
        this.controller.imports.Velocity(document.body, 'scroll', {
          offset: scrollTop,
          mobileHA: false,
          duration: 1000,
          easing: 'ease-in-out'
        })
      }
    }
  }

  // return true if we successfully navigated.
  // works only for editor or preview. and if uri is a page link
  tryNavigateToPage (uri) {
    const inPreviewMode = window.self !== window.top
    if ((this.controller.editorContext || inPreviewMode) && uri) {
      const isMailorTel = uri.startsWith('mailto:') || uri.startsWith('tel:')
      const isHref = uri.startsWith('http:') || uri.startsWith('https:')
      const isUndefined = uri === 'undefined'

      if (isMailorTel || isHref || isUndefined) {
        if (inPreviewMode) {
          return false // still handle normal links in preview mode
        }
      } else {
        // it's a page navigation
        const path = uri[0] === '/' ? uri.substr(1) : uri
        this.controller.imports.Communication.sendRedux('SET_ACTIVE_PAGE_URI', path)
        if (!this.controller.editable()) {
          // preview mode, scroll to top and let editor handle the navigation
          window.scrollTo(0, 0)
        }
      }
      return true
    }
  }

  disposeElement () {
    super.disposeElement()
    this.clearTimers()

    Features.removeFeaturesForControl(this)
  }

  clearTimers () {
    if (this.times && this.times.length > 0) {
      each(this.times, timer => {
        clearTimeout(timer)
      })
      this.times = null
    }
  }
}
export default ContentControl
