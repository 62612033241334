import BaseControl from 'editor/controls/base-control'

import $ from 'jquery'

import each from 'lodash/each'

import ImageProxyHelper from 'core/helpers/images/image-proxy-helper'

class ListControl extends BaseControl {
  constructor (controller, Layout, view = null) {
    super(controller, () => Layout, controller.model, 'list')
    this.view = view
  }

  bindLayout (element, data) {
    super.bindLayout(element, data)

    if (this.controller.editorContext && this.controller.editable()) {
      const addButton = element.querySelector('.kv-add-item-btn')
      if (addButton) {
        addButton.addEventListener('click', this.controller.addItemClick)
      }

      $(element)
        .find(' > *[data-type="list"]')
        .sortable({
          animation: 300,
          items: '*[data-type="item"]',
          handle: '.kv-drag-handle',
          tolerance: 'intersect',
          forcePlaceholder: true,
          revert: 300,
          start: (event, ui) => {
            this.controller.editorContext.selectionManager.disable()
            $(this.domElement)
              .find('.kv-selected')
              .removeClass('kv-selected')
            ui.item.addClass('kv-selected')
            this.domElement.classList.add('kv-dragging')
            ui.item.data('index', ui.item.index())

            ui.placeholder[0].style.backgroundColor = 'black'
            ui.placeholder[0].style.opacity = 1
          },
          stop: (event, ui) => {
            this.controller.editorContext.selectionManager.enable()
            this.domElement.classList.remove('kv-dragging')
            const oldIndex = ui.item.data('index')
            const newIndex = ui.item.index()

            this.controller.reorderItem(oldIndex, newIndex)
            ui.item.removeData('index')
            $(this.domElement)
              .find('.kv-selected')
              .removeClass('kv-selected')
            ui.item.removeClass('kv-selected')

            event.stopImmediatePropagation()
          }
        })

      const handle = $('*[data-type="list"] .kv-drag-handle', element)
      handle.mousedown(() => {
        this.controller.editorContext.selectionManager.disable()
      })
      handle.mouseup(() => {
        this.controller.editorContext.selectionManager.enable()
      })

      if (data.isRemoteList) {
        const addContainer = element.querySelector('.add-container')
        if (addContainer) {
          addContainer.remove()
        }
      }
    } else if (!this.controller.editorContext) {
      const galleryElements = element.querySelectorAll('.kv-gallery')
      if (galleryElements.length > 0) {
        this.galleryImages = []
        each(galleryElements, (galleryElement, index) => {
          const data = this.controller.model[index]
          let src
          // Get data from the element when there's no list model
          if (!data && galleryElement.tagName === 'DIV') {
            // Get everything between 'url( and )
            src = galleryElement.style.backgroundImage.match(/url\((.*?)\)/) || ''
            if (src.length > 0) {
              src = src[1].replace(/('|")/g, '') || ''
            }

            src = ImageProxyHelper.stripProxyUrl(src)
            if (src) {
              this.galleryImages.push({
                src: src,
                w: 0,
                h: 0,
                el: galleryElement
              })
            }
          } else if (data.image && data.image.value) {
            src = ImageProxyHelper.stripProxyUrl(data.image.value)
            if (src) {
              this.galleryImages.push({
                src: src,
                msrc: data.image.value,
                w: 0,
                h: 0,
                el: galleryElement,
                title: `<h4>${data.title || ''}</h4><p>${data.subtitle || ''}</p>`
              })
            }
          }

          if (!galleryElement.querySelector('a')) {
            // Don't open photoswipe when the image has a link attached
            galleryElement.addEventListener('click', event => this.openPhotoSwipe(event, index, galleryElement, false))
          }
        })
      }

      const swiper = element.querySelector('.swiper-container')
      if (swiper) {
        element.classList.add('swiper-section')
        const slides = swiper.children[0].children
        each(slides, slide => {
          ;[...slide.classList].map(className => {
            if (className.indexOf('col-') === 0) {
              slide.classList.remove(className)
            }
          })
          slide.classList.remove('content-item')
          slide.classList.remove('content-container')
          slide.classList.add('swiper-slide')
        })

        this.swiper = new this.controller.imports.Swiper(swiper, {
          slidesPerView: 1.3,
          centeredSlides: true,
          spaceBetween: 30,
          autoHeight: true,
          loop: true,
          pagination: {
            el: '.swiper-pagination',
            clickable: true
          }
        })
      }
    } else {
      const swiper = element.querySelector('.swiper-container')
      if (swiper) {
        swiper.remove()
      }
    }
  }

  openPhotoSwipe = (event, index, galleryElement, disableAnimation) => {
    event.stopImmediatePropagation()

    const pswpElement = document.querySelectorAll('.pswp')[0]
    const options = {
      // define gallery index (for URL)
      galleryUID: galleryElement.getAttribute('data-pswp-uid'),
      showHideOpacity: true,
      getThumbBoundsFn: index => {
        // See Options -> getThumbBoundsFn section of documentation for more info
        const thumbnail = this.galleryImages[index].el
        const pageYScroll = window.pageYOffset || document.documentElement.scrollTop
        const rect = thumbnail.getBoundingClientRect()
        return { x: rect.left, y: rect.top + pageYScroll, w: rect.width }
      }
    }
    options.index = parseInt(index, 10)

    // exit if index not found
    if (isNaN(options.index)) {
      return
    }

    if (disableAnimation) {
      options.showAnimationDuration = 0
    }

    // Pass data to PhotoSwipe and initialize it
    const gallery = new this.controller.imports.PhotoSwipe(pswpElement, this.controller.imports.PhotoSwipeUIDefault, this.galleryImages, options)
    gallery.listen('gettingData', (index, item) => {
      if (item.w < 1 || item.h < 1) {
        const image = new window.Image()
        image.onload = () => {
          item.w = image.width
          item.h = image.height
          gallery.invalidateCurrItems()
          gallery.updateSize(true)
        }
        image.src = item.src
      }
    })

    gallery.listen('destroy', () => {
      document.documentElement.classList.remove('fixed-overlay')
    })

    document.documentElement.classList.add('fixed-overlay')
    gallery.init()
  }
}
export default ListControl
